import { computed, inject, Injectable } from '@angular/core';
import { MixpanelService } from '@movinmotion/data-access-third-party';
import { AppDisplay, movinMotionApp } from './app-display.model';
import { AppDisplayService } from './app-display.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class MixpanelAuthService {
  private mixpanel = inject(MixpanelService);
  private appDisplayService = inject(AppDisplayService);

  appDisplay = toSignal(this.appDisplayService.getDisplay$());

  allowedServices: AppDisplay[] = [AppDisplay.worker];

  currentAppAllowed = computed(() => this.allowedServices.includes(this.appDisplay()));

  track(eventName: string, sendImmediately?: boolean): void {
    if (this.currentAppAllowed()) {
      this.mixpanel.track({
        eventName: this.formatEventName(eventName),
        data: {},
        sendImmediately,
      });
    }
  }

  formatEventName(eventName: string): string {
    return `[AUTH/${movinMotionApp[this.appDisplay()]}] - ${eventName}`;
  }
}
